
import { Component, Prop } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"

@Component({ components: {} })
export default class InstantUpdateBooleanIconButton extends InstantUpdateFieldMixin {
  @Prop({default: "mdi-alien"}) readonly onIcon !: string
  @Prop({default: "mdi-alien-outline"}) readonly offIcon !: string

  @Prop({default: ""}) readonly onTitle !: string
  @Prop({default: ""}) readonly offTitle !: string


  @Prop({}) readonly onColor !: string
  @Prop({}) readonly offColor !: string

  get halPropsObject() {
    return this.halProps(this.propName, this.$attrs);
  }
}
