
import {Component, Prop} from 'vue-property-decorator'
import HealthStatusChip from "@/components/healthreports/HealthStatusChip.vue"
import MediaImageStrip from "@/components/media/MediaImageStrip.vue"
import { BaseHealthReportComponent, RequiredProjections } from '@/components/base/BaseMixins'
import { ClientResource, PropertyResource } from '@/resources'
import InstantUpdateBooleanIconButton from '../forms/InstantUpdateBooleanIconButton.vue'

@RequiredProjections("healthReportDetail")
@Component({components: {HealthStatusChip, MediaImageStrip, InstantUpdateBooleanIconButton}})
export default class HealthReportListItem extends BaseHealthReportComponent {
  @Prop({ required : false, default: false }) readonly showSystem!: boolean
  @Prop({ required : false, default: false }) readonly showProperty!: boolean
  @Prop({ required : false, default: false }) readonly showSilenceAlert!: boolean  

  get cUtil() {
    return ClientResource
  }
  get pUtil() {
    return PropertyResource
  }
}
