
import {Component, ProvideReactive} from 'vue-property-decorator'
import PropertyMaintenanceSystemTabs from "@/components/properties/PropertyMaintenanceSystemTabs.vue"
import PropertyAddress from '@/components/properties/PropertyAddress.vue'
import HealthSummaryChips from "@/components/healthreports/HealthSummaryChips.vue"
import ClientName from "@/components/clients/ClientName.vue"
import { BasePropertyComponent } from '../base/BaseMixins'
import InstantUpdateBoolean from '../forms/InstantUpdateBoolean.vue'
import { PropertyResource } from '@/resources'

@Component({metaInfo: {title:process.env.VUE_APP_NAME + " - Property View"}, components: {InstantUpdateBoolean, HealthSummaryChips,PropertyAddress, ClientName, PropertyMaintenanceSystemTabs}})
export default class PropertyDetail extends BasePropertyComponent {

  @ProvideReactive() propertyResource : PropertyResource = this.resource
}
