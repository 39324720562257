
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import AsyncComputed from 'vue-async-computed-decorator'
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"

@Component({components: {PropertyMaintenanceSystemFormDialog}})
export default class PropertyMaintenanceSystemTabs extends Vue {
  @Prop({ required : true }) readonly propertyResource!: PropertyResource

  tab : string = "tab-overview"
  currentPmsId : any = null
  addDialog : boolean = false
  parentPropertyMaintenanceSystemResource : any = null
  useCache : boolean = true
 
  filterActiveSystems(systems : any) {
    return systems ? systems.filter((s : any) => !s.archived) : []
  }


  @AsyncComputed()
  async maintenanceSystems() {
    return this.propertyResource.maintenanceSystems
  }

  @AsyncComputed()
  async maintenanceSystemsTree() {
    // so vue can see the change
    //const useCache = this.useCache

    const mst = this.propertyResource.maintenanceSystemsTree

    const mstr = await mst.getAssociation({}, true) // TODO this.useCache
    //this.useCache = true
    this.parentPropertyMaintenanceSystemResource = new PropertyMaintenanceSystemsResource(mstr.data().id)
    return mst.getAssociation()
  }

  get pmsId() {
    return this.$router.currentRoute.params.pmsId
  }

  gotoOverview() {
    if (this.tab != "tab-overview") {
      this.$router.push({name: 'property', params: {pid : this.propertyResource.data().id}})
    }
  }
  
  gotoInbox() {
    if (this.tab != "tab-inbox") {
      this.$router.push({name: 'propertyInbox', params: {pid : this.propertyResource.data().id}})
    }
  }

  gotoPms(pmsId : any) {
    if (pmsId != this.currentPmsId) {
      this.$router.push({name: 'propertySystem', params: {pmsId : pmsId}})
    }
  }

  @Watch('$route', {immediate: true, deep: true})
  routeChanged(newRoute : any) {

    // check for situation where our URL is not exactly a top level tab
    if (newRoute.name === "propertySystem") {
      this.currentPmsId = newRoute.params.pmsId

      this.propertyResource.maintenanceSystemsTree.getAssociation().then((tree) => {
          const subsystemRoot = PropertyMaintenanceSystemsResource.subsystemRoot(tree.data(), this.currentPmsId, true)
          if (subsystemRoot) {
            this.tab = "tab-" + subsystemRoot.id
          }
        })        
    }
    else if (newRoute.name === "propertyInbox") {
      this.currentPmsId = null
      this.tab = "tab-inbox"
    }
    else {
      this.currentPmsId = null
      this.tab = "tab-overview"
    }
    
  }

  @Watch("propertyResource")
  propertyResourceChanged(newPr : any, oldPr : any) {
    if (newPr.data().id !== oldPr?.data().id) {
      //this.tab = "tab-overview"
    }
  }

}
