
import {Component, Prop} from 'vue-property-decorator'
import {FormsMetadataResource} from "@/resources"
import HealthReportListItem from './HealthReportListItem.vue'
import {groupBy} from "lodash-es"
import { BaseHealthReportComponent } from '@/components/base/BaseMixins'
// @ts-ignore
import VirtualScroll from 'vue-virtual-scroll-list'

@Component({components: {HealthReportListItem, VirtualScroll}})
export default class HealthSummaryChips extends BaseHealthReportComponent {

  @Prop() readonly showSystem !: boolean
  @Prop() readonly showSilenceAlert !: boolean
  @Prop() readonly showProperty !: boolean
  @Prop({default: "x-small"}) readonly chipSize !: string

  get statusSummaries() {
  
    var statusSummaries = this.resources as any[]
 
    var mappedByStatus : any = {} //"UNKNOWN" : [], "OK" : [], "WARNING" : [], "CRITICAL" : []}

    // group reports by status
    var grouped : any = groupBy(statusSummaries, (resource:any) => resource.data().status)

    // populate groups
    for (const group in grouped) {
        mappedByStatus[group] = grouped[group]
    }
    return mappedByStatus;
  }

  resourceKey(rs : any) {
    return rs.data().id
  }

  gotoSystem(item : any) {
    this.$router.push({ name: 'propertySystem', params: { pid: item.data().pid, pmsId: item.data().pmsId } })
  }

  get statusValues() {
    return FormsMetadataResource.Instance.getHalOptions("healthreport", "status")
  }

  resourcesWithStatus(statusName: any) {
    return this.resources.filter(r => r.data().status === statusName)
  }

  getStatusText(statusName : any) {
    return this.statusValues.find((s:any) => s.value === statusName).text
  }
}
